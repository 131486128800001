import './rankingCard.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { GetStorage } from '../../../config/utils/Storage';

interface DataItem {
    BZeroIndexMonth: number;
    RecollectionMonth: number;
    property: string | null;
    propertyName: string | null;
    propertyUnit: string | null;
    propertyUnitName: string | null;
}

interface Props {
    data: DataItem[];
}

const deffaultRole =
    parseInt(GetStorage(process.env.REACT_APP_PUBLIC_DEFFAULT_ROLE)) || 0;

const RankingCard = ({ data }: Props) => {
    const { preferences, userData }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const { roles } = userData;
    const { appSections } = roles[deffaultRole];
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                rankingCard: { title, subtitle, detailTitle },
                monthlyEvolutionCard: { months }
            }
        }
    } = lang;
    const [date, setDate] = useState<string>('');
    const [gestor, setGestor] = useState<boolean>(false);
    /* const [showData, setShowData] = useState<ShowDataItem[]>([]); */
    const navigate = useNavigate();

    useEffect(() => {
        for (const role of roles) {
            if (role.gestorData) {
                setGestor(true);
                break;
            }
        }
    }, [userData]);

    const formatRecollection = value => {
        if (value >= 1000000) {
            // 1,000,000 gramos = 1 tonelada
            return `${(value / 1000000).toLocaleString('es-CL', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            })} ton`;
        } else if (value >= 1000 && value < 1000000) {
            // 1,000 gramos = 1 kilogramo
            return `${(value / 1000).toLocaleString('es-CL', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            })} kg`;
        } else {
            return `${value.toLocaleString('es-CL', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1
            })} g`;
        }
    };

    useEffect(() => {
        const currentDate = new Date();
        let currentMonth = currentDate.getMonth();
        let currentYear = currentDate.getFullYear();
        const currentDay = currentDate.getDate();
        if (currentDay > 10) {
            currentMonth -= 1;
        } else {
            currentMonth -= 2;
        }
        if (currentMonth < 0) {
            currentMonth += 12;
            currentYear -= 1;
        }
        const monthName = months[currentMonth];
        const finalResult = `${monthName} ${currentYear}`;
        setDate(finalResult);
    }, []);

    return (
        <div className='ranking-card'>
            <div className='ranking-card__titulo-card title'>
                Top 10 {title}
            </div>
            <div className='ranking-card__subtitulo-card subtitle'>
                {subtitle} {date}
            </div>
            <div className='ranking-card__content-container'>
                <div className='ranking-card__content-container__content'>
                    <div className='ranking-card__content-container__content__podio-container'>
                        <div className='ranking-card__content-container__content__podio-container__item'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/SecondPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {data?.[1]?.propertyName ??
                                    data?.[1]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #2
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollection(
                                            data?.[1]?.RecollectionMonth || 0
                                        )
                                    ) : (
                                        <>
                                            {(
                                                data?.[1]?.BZeroIndexMonth * 100
                                            ).toLocaleString('es-CL', {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1
                                            }) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='ranking-card__content-container__content__podio-container__item firstPlace'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/FirstPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {(data?.length > 1 &&
                                    data?.[0]?.propertyName) ??
                                    data?.[0]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #1
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollection(
                                            data?.[0]?.RecollectionMonth || 0
                                        )
                                    ) : (
                                        <>
                                            {(
                                                data?.[0]?.BZeroIndexMonth * 100
                                            ).toLocaleString('es-CL', {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1
                                            }) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='ranking-card__content-container__content__podio-container__item'>
                            <img
                                className='ranking-card__content-container__content__podio-container__item__img'
                                src='/svg-icons/ThirdPlaceIcon.svg'
                            />
                            <div className='ranking-card__content-container__content__podio-container__item__name subsubtitle'>
                                {data?.[2]?.propertyName ??
                                    data?.[2]?.propertyUnitName ??
                                    ''}
                            </div>
                            <div className='ranking-card__content-container__content__podio-container__item__value'>
                                <div className='ranking-card__content-container__content__podio-container__item__value__number title'>
                                    #3
                                </div>
                                <div className='ranking-card__content-container__content__podio-container__item__value__percentage subsubtitle'>
                                    {gestor ? (
                                        formatRecollection(
                                            data?.[2]?.RecollectionMonth || 0
                                        )
                                    ) : (
                                        <>
                                            {(
                                                data?.[2]?.BZeroIndexMonth * 100
                                            ).toLocaleString('es-CL', {
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1
                                            }) || ''}
                                            %
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ranking-card__content-container__content__list-container'>
                        {data?.length > 3 &&
                            data
                                .slice(3, Math.min(10, data.length))
                                .map((item, index) => (
                                    <div
                                        key={item.property ?? item.propertyUnit}
                                        className='ranking-card__content-container__content__list-container__element'
                                    >
                                        <div className='ranking-card__content-container__content__list-container__element__info'>
                                            <div className='ranking-card__content-container__content__list-container__element__info__number title'>
                                                #{index + 4}
                                            </div>
                                            <img
                                                className='ranking-card__content-container__content__list-container__element__info__img'
                                                src='/svg-icons/DotIcon.svg'
                                            />
                                            <div className='ranking-card__content-container__content__list-container__element__info__name subsubtitle'>
                                                {item.propertyName ??
                                                    item.propertyUnitName}
                                            </div>
                                            <img
                                                className='ranking-card__content-container__content__list-container__element__info__img'
                                                src='/svg-icons/DotIcon.svg'
                                            />
                                            <div className='ranking-card__content-container__content__list-container__element__info__percentage subsubtitle'>
                                                {gestor ? (
                                                    formatRecollection(
                                                        item?.RecollectionMonth ||
                                                            0
                                                    )
                                                ) : (
                                                    <>
                                                        {(
                                                            item?.BZeroIndexMonth *
                                                            100
                                                        ).toLocaleString(
                                                            'es-CL',
                                                            {
                                                                minimumFractionDigits: 1,
                                                                maximumFractionDigits: 1
                                                            }
                                                        ) || ''}
                                                        %
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
                {data.length < 2 && (
                    <div className='ranking-card__content-container__alert subtitle'>
                        Seleccione más de 1 inmueble/unidad con basura para ver
                        el ranking
                    </div>
                )}
                {appSections.some(item => item.name.includes('Ranking')) && (
                    <button
                        className='button button-body--ranking'
                        onClick={() => navigate('/panel/ranking')}
                    >
                        <div className='button-body--ranking__content'>
                            <div className='button-body--ranking__content__text'>
                                {detailTitle}
                            </div>
                            <img
                                className='button-body--ranking__content__img'
                                src='/svg-icons/Arrows.svg'
                            />
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default RankingCard;
