//  <--COMPONENTS--> //
import { useEffect, useState /* , useRef */ } from 'react';
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MoreOutlined
} from '@ant-design/icons';
//  <--REDUX--> //
import { useAppSelector } from '../../../../store/hooks';
import { PropertyStoreInterface } from '../../../../store/slices/property/property.interface';
import { PropertyUnitStoreInterface } from '../../../../store/slices/property-unit/property-unit.interface';
import { UserStoreInterface } from '../../../../store/slices/users/user.interface';
import '../garbage-control.scss';
import { useCallApi } from '../../../../config/hooks/useCallApi';
import { HTTP_METHODS } from '../../../../config/hooks/useCallApi/constants';
import { FormatLabels } from '../../../../config/utils/IdbFormatData';
import { IDB } from '../../../../config/utils/IndexedDb';
import { lang } from '../../../langs';
import {
    ApiGarbageCostByProperty,
    ApiGarbageProratioFilter,
    ApiGarbageCostDetailFilter
} from '../../../../config/service';
import GarbageCostsDetails from './garbage-cost-detail';
import GarbageConsumptionEdit from './garbage-consumption-edit';
import GarbageDeliveriesTable from './garbage-deliveries-table';
import { ConfigProvider, Popover } from 'antd';
import { GarbageProratioInterface } from '../../../../interface';

const dbName = process.env.REACT_APP_PUBLIC_INDEXEDDB ?? 'pryma';
const tbl2name = 'garbage-control';

interface Props {
    onChange: (garbageCost: string, date: string, showCosts: boolean) => void;
    showDetails: boolean;
}

const GarbageCostsTable = ({ onChange, showDetails }: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            pageDataCenter: { months }
        }
    } = lang;
    const { GetData, LoadingData, LoaderElement } = useCallApi();
    const {
        /* AddDataToDb, */
        GetAllDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        GetDataDb, // this function triggers an effect that updates the data, the parameter that brings the data is ObtainedData
        ObtainedData
    } = IDB(dbName);
    const { selected }: PropertyStoreInterface = useAppSelector(
        state => state.property
    );
    const { selectedUnits }: PropertyUnitStoreInterface = useAppSelector(
        state => state.propertyUnit
    );

    const [labels, setLabels] = useState<string[]>([]);
    const [dateFilterRange, setDateFilterRange] = useState<string[]>(['']);
    const [garbageData, setGarbageData] = useState<any[]>([]);
    const [garbageCost, setGarbageCost] = useState<any>({});
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<number>(0);
    const [summaryDetail, setSummaryDetail] = useState<any[]>([]);
    const [tableData, setTableData] = useState<GarbageProratioInterface[]>([]);
    const [managementDetailData, setManagementDetailData] = useState<any[]>([]);
    const [totalConsumption, setTotalConsumption] = useState<number>(0);
    const [totalCost, setTotalCost] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showCosts, setShowCosts] = useState<boolean>(false);
    const [expandOpen, setExpandOpen] = useState<boolean>(false);

    const bags: any[] = [
        {
            name: 'Un 30 l',
            capacity: 30,
            selected: true
        },
        {
            name: 'Un 80 l',
            capacity: 80,
            selected: true
        },
        {
            name: 'Un 140 l',
            capacity: 140,
            selected: true
        },
        {
            name: 'Un 220 l',
            capacity: 220,
            selected: true
        }
    ];

    useEffect(() => {
        const currentMonthIndex = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        const currentMonth = months[currentMonthIndex];
        let iniMonth = currentMonthIndex - 2;
        let iniYear = currentYear;

        if (iniMonth < 0) {
            iniMonth = months.length + iniMonth;
            iniYear -= 1;
        }
        const iniMonthStr = String(iniMonth + 1).padStart(2, '0');
        const finMonthStr = String(currentMonthIndex + 1).padStart(2, '0'); // +1 porque los meses se suelen numerar de 1 a 12
        const newDateFilterRange = [
            `${iniYear}${iniMonthStr}`,
            `${currentYear}${finMonthStr}`
        ];
        setDateFilterRange(newDateFilterRange);
        setMonth(currentMonth);
        setYear(currentYear);
    }, [months]);

    useEffect(() => {
        const idsFromSelected = selected.flatMap(item => {
            return item.propertyUnits.map(unit => unit.id);
        });
        const idsFromSelectedUnits = selectedUnits.map(item => item.id);
        const idsUnits = [...idsFromSelected, ...idsFromSelectedUnits];

        const finalFilter = {
            tables: [tbl2name],
            filters: {
                keys: ['propertyId'],
                valuesProperty: [],
                valuesPropertyUnits: idsUnits
            }
        };
        if (labels.length > 0) {
            if (selectedUnits.length + selected.length >= 1) {
                GetDataDb({ ...finalFilter, dateFilterRange });
            } else {
                GetAllDataDb({
                    tables: [tbl2name],
                    filters: {
                        keys: [],
                        valuesProperty: []
                    },
                    dateFilterRange
                });
            }
        }
    }, [labels, selected, selectedUnits, dateFilterRange]);

    useEffect(() => {
        if (ObtainedData['garbage-control']) {
            setGarbageData(ObtainedData['garbage-control']);
        }
    }, [ObtainedData]);

    useEffect(() => {
        GetGarbageCost();
    }, [selected, selectedUnits]);

    useEffect(() => {
        FormatTableData();
    }, [garbageData]);

    useEffect(() => {
        CallData();
    }, []);

    useEffect(() => {
        console.log(showDetails);
    }, [showDetails]);

    useEffect(() => {
        if (dateFilterRange[1]) {
            GetTableData(dateFilterRange[1]);
        }
    }, [garbageCost, dateFilterRange, refresh, selectedUnits]);

    useEffect(() => {
        onChange(garbageCost, dateFilterRange[1], showCosts);
    }, [garbageCost, dateFilterRange]);

    const GetGarbageCost = async () => {
        if (selected.length === 1 && selectedUnits.length === 0) {
            const property = selected[0].id;
            const response = await GetData(
                ApiGarbageCostByProperty(property),
                HTTP_METHODS.GET
            );
            setGarbageCost(response.data);
            setShowCosts(true);
        } else if (selected.length === 0 && selectedUnits.length > 0) {
            const firstProperty = selectedUnits[0].property;
            const allSameProperty = selectedUnits.every(
                unit => unit.property.id === firstProperty.id
            );
            if (allSameProperty) {
                const response = await GetData(
                    ApiGarbageCostByProperty(firstProperty.id),
                    HTTP_METHODS.GET
                );
                setGarbageCost(response.data);
                setShowCosts(true);
            } else {
                setShowCosts(false);
            }
        } else {
            setShowCosts(false);
        }
    };

    const GetTableData = async date => {
        const values = {
            date: `${date.slice(0, 4)}-${date.slice(4)}`,
            garbageCost: garbageCost?.id
        };
        const response2 = await GetData(
            ApiGarbageCostDetailFilter,
            HTTP_METHODS.POST,
            values
        );
        setManagementDetailData(response2.data);
        const response = await GetData(
            ApiGarbageProratioFilter,
            HTTP_METHODS.POST,
            values
        );
        const dataPropertyUnits =
            selectedUnits.length > 0
                ? response.data.filter(item =>
                      selectedUnits.some(
                          unit => unit.id === item.propertyUnit?.id
                      )
                  )
                : response.data.filter(item => item.propertyUnit !== null);
        const dataProperties = response.data.filter(
            item => item.propertyUnit === null
        );

        // Ordenar el arreglo con propertyUnit
        const sortedData = dataPropertyUnits.sort((a, b) =>
            a.propertyUnit.name.localeCompare(b.propertyUnit.name)
        );
        setTableData(sortedData);
        const totalConsumption = dataProperties.reduce((sum: number, item) => {
            return sum + parseFloat(item.consumptionL);
        }, 0);

        const totalCost = dataProperties.reduce((sum: number, item) => {
            return sum + parseFloat(item.managementCost);
        }, 0);

        setTotalConsumption(totalConsumption);
        setTotalCost(totalCost);
    };

    const FormatTableData = () => {
        const groupedData: any[] = Object.values(
            garbageData.reduce((acc, item) => {
                // Agrupar solo si la fecha es igual a dateFilterRange[1]

                const supplyId = item.supply.id;

                if (!acc[supplyId]) {
                    acc[supplyId] = {
                        supply: item.supply,
                        totalMonth: 0,
                        mediaTrim: 0,
                        count: 0 // Para calcular el promedio
                    };
                }
                if (item.date === dateFilterRange[1]) {
                    acc[supplyId].totalMonth =
                        parseInt(acc[supplyId].totalMonth) +
                        parseInt(item.total);
                }
                acc[supplyId].count = parseInt(acc[supplyId].count) + 1;
                acc[supplyId].mediaTrim =
                    parseInt(acc[supplyId].mediaTrim) + parseInt(item.total); // Sumar para promedio

                return acc;
            }, {})
        );
        groupedData.forEach(item => {
            item.mediaTrim = item.mediaTrim / 3;
        });
        groupedData.sort(
            (a, b) =>
                parseFloat(a.supply.capacity) - parseFloat(b.supply.capacity)
        );
        setSummaryDetail(groupedData);
    };

    const CallData = async () => {
        const labelsData = localStorage.getItem('labels');
        const labels = labelsData ? JSON.parse(labelsData) : null;
        const arrayLabels = FormatLabels(labels);
        setLabels(arrayLabels);
        GetAllDataDb({
            tables: [tbl2name],
            filters: {
                keys: [],
                valuesProperty: []
            },
            dateFilterRange
        });
    };

    const changeMonth = (way: number) => {
        const currentIndex = months.indexOf(month);
        let newIndex = currentIndex + way;
        let newYear = year;
        let iniMonth = newIndex - 2;
        let iniYear = newYear;

        if (newIndex < 0) {
            newIndex = months.length + newIndex;
            newYear -= 1;
        } else if (newIndex >= months.length) {
            newIndex = newIndex % months.length;
            newYear += 1;
        }

        if (iniMonth < 0) {
            iniMonth = months.length + iniMonth;
            iniYear -= 1;
        } else if (iniMonth >= months.length) {
            iniMonth = iniMonth % months.length;
            iniYear += 1;
        }

        // Asegura que el índice del mes tenga dos dígitos
        const iniMonthStr = String(iniMonth + 1).padStart(2, '0'); // +1 porque los meses se suelen numerar de 1 a 12
        const newMonthStr = String(newIndex + 1).padStart(2, '0'); // +1 porque los meses se suelen numerar de 1 a 12
        const newDateFilterRange = [
            `${iniYear}${iniMonthStr}`,
            `${newYear}${newMonthStr}`
        ];
        setDateFilterRange(newDateFilterRange);
        setMonth(months[newIndex]);
        setYear(newYear);
    };

    const Refresh = async () => {
        setRefresh(!refresh);
    };

    return (
        <div
            className={`${
                expandOpen ? 'garbage-expand' : 'garbagecontrol__costs__content'
            }`}
        >
            {!showCosts ? (
                <div className='garbagecontrol__costs__content__message subtitle'>
                    Debes tener 1 solo inmueble seleccionado o unidades del
                    mismo inmueble para visualizar su control costos y entrega
                    de bolsas
                </div>
            ) : (
                <>
                    <div
                        className={`${
                            expandOpen
                                ? 'garbagecontrol__costs__content__header-expand'
                                : 'garbagecontrol__costs__content__header'
                        }`}
                    >
                        <button
                            onClick={() => setExpandOpen(!expandOpen)}
                            className='garbagecontrol__costs__content__header__expandIcon'
                        >
                            <img
                                className='garbagecontrol__costs__content__header__expandIcon__img'
                                src={`/svg-icons/${
                                    expandOpen
                                        ? 'ContractIcon.svg'
                                        : 'ExpandIcon.svg'
                                }`}
                            />
                        </button>
                        <div className='garbagecontrol__costs__content__header__months'>
                            <button
                                className='garbagecontrol__costs__content__header__months__btn subtitle'
                                onClick={() => changeMonth(-1)}
                            >
                                <DoubleLeftOutlined
                                    style={{ color: '#C4C3C5' }}
                                    rev={''}
                                />
                                Mes anterior
                            </button>
                            <div className='garbagecontrol__costs__content__header__months__title'>
                                {month} {year}
                            </div>
                            <button
                                className='garbagecontrol__costs__content__header__months__btn subtitle'
                                onClick={() => changeMonth(1)}
                            >
                                Mes siguiente
                                <DoubleRightOutlined
                                    style={{ color: '#C4C3C5' }}
                                    rev={''}
                                />
                            </button>
                        </div>
                    </div>
                    <div className='garbagecontrol__costs__content__summary'>
                        <div className='garbagecontrol__costs__content__summary__totals'>
                            <div className='garbagecontrol__costs__content__summary__totals__item'>
                                <div className='garbagecontrol__costs__content__summary__totals__item__title title'>
                                    Consumo total mes
                                    <div className='garbagecontrol__costs__content__summary__totals__item__title__subtitle subsubtitle'>
                                        +x% del mes anterior
                                    </div>
                                </div>
                                <div className='garbagecontrol__costs__content__summary__totals__item__number bigtitle'>
                                    {totalConsumption.toLocaleString('es-CL', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1
                                    })}{' '}
                                    l
                                </div>
                            </div>
                            <div className='garbagecontrol__costs__content__summary__totals__item'>
                                <div className='garbagecontrol__costs__content__summary__totals__item__title title'>
                                    Costo total gestión mes
                                    <div className='garbagecontrol__costs__content__summary__totals__item__title__subtitle subsubtitle'>
                                        +x% del mes anterior
                                    </div>
                                </div>
                                <div className='garbagecontrol__costs__content__summary__totals__item__number bigtitle'>
                                    $
                                    {totalCost.toLocaleString('es-CL', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1
                                    })}
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                Popover: {
                                                    zIndexPopup: 2
                                                }
                                            }
                                        }}
                                    >
                                        <Popover
                                            className='garbagecontrol__popover'
                                            placement='bottom'
                                            trigger='click'
                                            /* visible={popoverGDVisible}
                                            onVisibleChange={(visible) => setPopoverGDVisible(true)} */
                                            content={
                                                <GarbageCostsDetails
                                                    data={managementDetailData}
                                                    property={
                                                        selected.length > 0
                                                            ? selected[0].name
                                                            : ''
                                                    }
                                                    garbageCost={garbageCost}
                                                    date={dateFilterRange[1]}
                                                    onRefresh={() => {
                                                        Refresh();
                                                        /* setPopoverGDVisible(false); */
                                                    }}
                                                />
                                            }
                                        >
                                            <MoreOutlined
                                                style={{ color: '#1f1f1f' }}
                                                rev={''}
                                            />
                                        </Popover>
                                    </ConfigProvider>
                                </div>
                            </div>
                        </div>
                        <div className='garbagecontrol__costs__content__summary__detail'>
                            <div className='garbagecontrol__costs__content__summary__detail__title'>
                                Total de bolsas entregadas
                                {/* <div className='garbagecontrol__costs__content__summary__detail__title__unit subtitle'>
                                    <button>masa</button>|
                                    <button>volumen</button>
                                </div> */}
                            </div>
                            <div className='garbagecontrol__costs__content__summary__detail__row  subtitle'>
                                <>
                                    <div className='garbagecontrol__costs__content__summary__detail__row__item'></div>
                                    {bags.map((item, index) => (
                                        <div
                                            className='garbagecontrol__costs__content__summary__detail__row__item'
                                            key={index}
                                        >
                                            {item.name}
                                        </div>
                                    ))}
                                </>
                            </div>
                            <div className='garbagecontrol__costs__content__summary__detail__row  subtitle'>
                                <div className='garbagecontrol__costs__content__summary__detail__row__item'>
                                    <div className='garbagecontrol__costs__content__summary__detail__row__item__title'>
                                        Mensual
                                    </div>
                                </div>
                                {bags.map((bag, index) => {
                                    const summaryItem = summaryDetail.find(
                                        item =>
                                            bag.capacity ===
                                            parseInt(item.supply.capacity)
                                    );

                                    return (
                                        <div
                                            key={index}
                                            className='garbagecontrol__costs__content__summary__detail__row__item'
                                        >
                                            {summaryItem
                                                ? summaryItem.totalMonth.toLocaleString(
                                                      'es-CL',
                                                      {
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 1
                                                      }
                                                  )
                                                : '0'}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='garbagecontrol__costs__content__summary__detail__row subtitle'>
                                <div className='garbagecontrol__costs__content__summary__detail__row__item'>
                                    <div className='garbagecontrol__costs__content__summary__detail__row__item__title'>
                                        Promedio trimestral
                                    </div>
                                </div>
                                {bags.map((bag, index) => {
                                    const summaryItem = summaryDetail.find(
                                        item =>
                                            bag.capacity ===
                                            parseInt(item.supply.capacity)
                                    );

                                    return (
                                        <div
                                            key={index}
                                            className='garbagecontrol__costs__content__summary__detail__row__item'
                                        >
                                            {summaryItem
                                                ? summaryItem.mediaTrim.toLocaleString(
                                                      'es-CL',
                                                      {
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 1
                                                      }
                                                  )
                                                : '0'}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div
                        className={`garbagecontrol__costs__content__tableContainer subtitle ${
                            !showDetails && 'tableContainerTall'
                        }`}
                    >
                        <div className='garbagecontrol__costs__content__tableContainer__div'>
                            <table className='garbagecontrol__costs__content__tableContainer__div__table subtitle'>
                                <thead className='garbagecontrol__costs__content__tableContainer__div__table__head'>
                                    <tr className='garbagecontrol__costs__content__tableContainer__div__table__head__row'>
                                        <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                                            Unidad
                                        </th>
                                        <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                                            Consumo de basura (l)
                                        </th>
                                        <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                                            Costo gestión
                                        </th>
                                        <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                                            Costo bolsas
                                        </th>
                                        <th className='garbagecontrol__costs__content__tableContainer__div__table__head__row__item'>
                                            Costo total neto
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='garbagecontrol__costs__content__tableContainer__div__table__body'>
                                    {tableData.map((item, index) => (
                                        <tr
                                            className='garbagecontrol__costs__content__tableContainer__div__table__body__row'
                                            key={item.id}
                                        >
                                            <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                                                {item.propertyUnit?.name}
                                            </td>
                                            <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                                                <div className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item__container'>
                                                    {parseFloat(
                                                        item.consumptionL
                                                    ).toLocaleString('es-CL', {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 1
                                                    })}
                                                    <ConfigProvider
                                                        theme={{
                                                            components: {
                                                                Popover: {
                                                                    zIndexPopup: 2
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <Popover
                                                            className='garbagecontrol__popover'
                                                            placement='bottom'
                                                            trigger='click'
                                                            /* visible={popoverVisible === index + 1}
                                                            onVisibleChange={(visible) => setPopoverVisible(index + 1)} */
                                                            content={
                                                                <GarbageConsumptionEdit
                                                                    garbageProratio={
                                                                        item
                                                                    }
                                                                    garbageCost={
                                                                        garbageCost
                                                                    }
                                                                    date={
                                                                        dateFilterRange[1]
                                                                    }
                                                                    onRefresh={() => {
                                                                        Refresh();
                                                                        /* setPopoverVisible(0); */
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <MoreOutlined
                                                                style={{
                                                                    color: '#1f1f1f'
                                                                }}
                                                                rev={''}
                                                            />
                                                        </Popover>
                                                    </ConfigProvider>
                                                </div>
                                            </td>
                                            <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                                                {parseFloat(
                                                    item.managementCost
                                                ).toLocaleString('es-CL', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 1
                                                })}
                                            </td>
                                            <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                                                {parseFloat(
                                                    item.bagsCost
                                                ).toLocaleString('es-CL', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 1
                                                })}
                                            </td>
                                            <td className='garbagecontrol__costs__content__tableContainer__div__table__body__row__item'>
                                                {(
                                                    parseInt(
                                                        item.managementCost
                                                    ) + parseInt(item.bagsCost)
                                                ).toLocaleString('es-CL', {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 1
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {showDetails && (
                        <div className='garbagecontrol__costs__content__tableContainer subtitle'>
                            <div className='garbagecontrol__costs__content__tableContainer__div'>
                                <GarbageDeliveriesTable data={garbageData} />
                            </div>
                        </div>
                    )}
                    {LoadingData && <LoaderElement />}
                </>
            )}
        </div>
    );
};

export default GarbageCostsTable;
