import './ecoequivalencesCard.scss';
import EcoequivalenceItem from './ecoequivalenceItem/ecoequivalenceItem';
import { useState, useEffect } from 'react';
import { lang } from '../../langs';
import { UserStoreInterface } from '../../../store/slices/users/user.interface';
import { useAppSelector } from '../../../store/hooks';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface Props {
    labels: string[];
    rangeCertificate?: number[];
    certificate?: boolean;
    data?: any[];
}

interface EcoequivalenceItemInterface {
    label: string;
    total: number;
    eqlabel: string;
    equivalent: number;
    unit: string;
    logo: string;
    type: string;
}

const EcoequivalencesCard = ({
    labels,
    certificate,
    data,
    rangeCertificate
}: Props) => {
    const { preferences }: UserStoreInterface = useAppSelector(
        state => state.users
    );
    const defaultLang: string = preferences.lang;
    const key = defaultLang;
    const {
        [key as keyof typeof lang]: {
            dataCenterComponents: {
                ecoequivalencesCard: { title, subtitle, detailMessage },
                recolectionCard: {
                    months,
                    lastMonth,
                    lastQuarter,
                    historic
                    /* selectedRange */
                }
            }
        }
    } = lang;
    const [items, setItems] = useState<EcoequivalenceItemInterface[]>([]);
    const [period, setPeriod] = useState<number>(2);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState(0);
    const [range, setRange] = useState<number[]>([]);

    useEffect(() => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const labelsLength = labels.length;

        if (period === 0) {
            // Si el periodo es 0, tomar el último valor del arreglo de labels
            if (actualDay > 10) {
                setRange([labelsLength - 2, labelsLength - 1]);
            } else {
                setRange([labelsLength - 3, labelsLength - 2]);
            }
        } else if (period === 1) {
            // Si el periodo es 1, obtener los últimos 3 si estamos a más de 10 del mes
            if (actualDay > 10) {
                // Últimos 3 valores del arreglo
                setRange([
                    labelsLength - 3,
                    labelsLength - 2,
                    labelsLength - 1
                ]);
            } else {
                // Penúltimos 3 valores del arreglo
                setRange([
                    labelsLength - 4,
                    labelsLength - 3,
                    labelsLength - 2
                ]);
            }
        } else {
            if (actualDay > 10) {
                setRange([0, labelsLength - 1]);
            } else {
                setRange([0, labelsLength - 2]);
            }
        }
    }, [period, labels]);

    useEffect(() => {
        if (rangeCertificate) {
            setRange([0, rangeCertificate[1]]);
        }
    }, [rangeCertificate]);

    useEffect(() => {
        const actualDate = new Date();
        const actualDay = actualDate.getDate();
        const dateCard = new Date(actualDate); // Copia de la fecha actual
        if (actualDay > 13) {
            dateCard.setMonth(actualDate.getMonth() - 1);
        } else {
            dateCard.setMonth(actualDate.getMonth() - 2);
        }
        const numberMonth = dateCard.getMonth();
        const yearResult = dateCard.getFullYear();
        const monthResult = months[numberMonth];
        setMonth(monthResult);
        setYear(yearResult);
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            const dataSummaryComplete = data?.map(item => {
                const totalInRange = item.total.slice(range[0], range[1]);
                const equivalentInRange = item.equivalent.slice(
                    range[0],
                    range[1]
                );
                const newTotal = totalInRange.reduce(
                    (acc, curr) => parseFloat(acc) + parseFloat(curr),
                    0
                );
                const newEquivalent = equivalentInRange.reduce(
                    (acc, curr) => parseFloat(acc) + parseFloat(curr),
                    0
                );

                // Actualizar los valores y propiedades de acuerdo al tipo
                switch (item.type) {
                    case 'trees':
                        return {
                            ...item,
                            total: newTotal,
                            equivalent: newEquivalent,
                            label: 'Árboles salvados',
                            unit: '',
                            eqlabel: ' resmas de papel',
                            logo: '/svg-icons/eco-equivalences/trees.svg'
                        };
                    case 'co2':
                        return {
                            ...item,
                            total: newTotal > 1000 ? newTotal / 1000 : newTotal,
                            equivalent: newEquivalent,
                            unit: newTotal > 1000 ? 'ton' : 'kg',
                            label: 'CO2 no emitido',
                            eqlabel: ' autos sin salir por un día',
                            logo: '/svg-icons/eco-equivalences/co2.svg'
                        };
                    case 'energy':
                        return {
                            ...item,
                            total: newTotal > 1000 ? newTotal / 1000 : newTotal,
                            equivalent: newEquivalent,
                            unit: newTotal > 1000 ? 'MWh' : 'kWh',
                            label: 'Energía evitada',
                            eqlabel: ' meses de consumo de un hogar',
                            logo: '/svg-icons/eco-equivalences/energy.svg'
                        };
                    case 'water':
                        return {
                            ...item,
                            total: newTotal > 1000 ? newTotal / 1000 : newTotal,
                            equivalent: newEquivalent,
                            unit: newTotal > 1000 ? 'm³' : 'l',
                            label: 'Agua evitada',
                            eqlabel: ' horas regando',
                            logo: '/svg-icons/eco-equivalences/water.svg'
                        };
                    default:
                        return item;
                }
            });
            setItems(dataSummaryComplete);
        }
    }, [data, range]);

    function getLastQuarter(month, months) {
        const monthIndex = months.indexOf(month);
        if (monthIndex === -1) {
            return 'No válido';
        }
        const lastQuarter =
            monthIndex - 2 < 0
                ? parseInt(months.length) + (monthIndex - 2)
                : monthIndex - 2;
        return `${months[lastQuarter]} -${` `}${month}`;
    }

    return (
        <div className='ecoequivalences-card'>
            {!certificate && (
                <>
                    <div className='ecoequivalences-card__titulo-card title'>
                        {title}
                    </div>
                    <div className='ecoequivalences-card__subtitulo-card subtitle'>
                        <div className='ecoequivalences-card__subtitulo-card__subtitle subtitle'>
                            {subtitle}:{' '}
                            {period === 0
                                ? month
                                : period === 1
                                ? getLastQuarter(month, months)
                                : historic}{' '}
                            {(period === 0 || period === 1) && year}
                            <Tooltip
                                title={
                                    <span className='main_menu__grid-container__tooltip'>
                                        {detailMessage}
                                    </span>
                                }
                                color='#fff'
                            >
                                <InfoCircleOutlined
                                    className='ecoequivalences-card__subtitulo-card__subtitle__icon'
                                    rev={''}
                                />
                            </Tooltip>
                        </div>
                        <div className='ecoequivalences-card__subtitulo-card__date subtitle'>
                            <select
                                className='ecoequivalences-card__subtitulo-card__date__select subtitle'
                                onChange={e =>
                                    setPeriod(e.target.selectedIndex)
                                }
                                defaultValue={2}
                            >
                                <option className='subtitle' value={0}>
                                    {lastMonth}
                                </option>
                                <option className='subtitle' value={1}>
                                    {lastQuarter}
                                </option>
                                <option className='subtitle' value={2}>
                                    {historic}
                                </option>
                                {/* <option className='subtitle'>{selectedRange}</option> */}
                            </select>
                        </div>
                    </div>
                </>
            )}
            <div
                className={
                    certificate
                        ? 'ecoequivalences-card__line-container'
                        : 'ecoequivalences-card__grid-container'
                }
            >
                {items.map((item, index) => {
                    return (
                        <EcoequivalenceItem
                            key={item.type}
                            logo={item.logo}
                            name={item.label}
                            kg={`${item.total} `}
                            eqlabel={item.eqlabel}
                            eq={`${item.equivalent}`}
                            unit={item.unit}
                            type={item.type}
                            certificate={certificate}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EcoequivalencesCard;
