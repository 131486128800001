import { StackedBarChart } from '../../../charts';
import { useEffect } from 'react';

const StackedDC = StackedBarChart;
StackedDC.Config.plugins.legend.display = false;
StackedDC.Config.plugins.title.text = '';

interface Props {
    data: any;
    formattedLabels: any;
    labels: any;
}

const ChartFormated = ({ data, labels, formattedLabels }: Props) => {
    useEffect(() => {
        const totals: Record<number, number> = {};
        data?.datasets?.forEach(item => {
            if (item.label !== 'Índice BZero') {
                item.data.forEach((value, index) => {
                    // Convierte el valor a un número y súmalo al total correspondiente
                    totals[index] = (totals[index] || 0) + parseFloat(value);
                });
            }
        });

        // Ahora, calcula el máximo de los totales
        const maxTotal = Math.max(...Object.values(totals));
        if (maxTotal > 1000) {
            StackedDC.AddTicks.title.text = 'Volumen [mil l]';
        } else {
            StackedDC.AddTicks.title.text = 'Volumen [l]';
        }

        StackedDC.Config.plugins.legend.display = false;
        StackedDC.Config.plugins.title.text = '';
        StackedDC.Config.scales.x.display = true;
        StackedDC.Config.scales.y = {
            ...StackedDC.Config.scales.y,
            ...StackedDC.AddTicks
        };

        // Añadir el título al eje y1
        if (StackedDC.Config.scales.y1) {
            StackedDC.Config.scales.y1.title = {
                display: true,
                align: 'center',
                text: 'Costo mensual bolsas [$]',
                font: {
                    size: 10,
                    weight: 'bold',
                    family: 'Roboto'
                }
            };
        }
    }, [data]);

    return (
        <>
            {data && (
                <StackedDC.StackedBarChartComponent
                    chartData={data}
                    options={{
                        ...StackedDC.Config,
                        scales: {
                            ...StackedDC.Config.scales,
                            x: {
                                ...StackedDC.Config.scales.x,
                                ticks: {
                                    ...StackedDC.Config.scales.x.ticks,
                                    callback: (value, index, values) => {
                                        return formattedLabels[index];
                                    }
                                }
                            }
                        }
                    }}
                    dateChangeManager={() => {}}
                />
            )}
        </>
    );
};

export default ChartFormated;
